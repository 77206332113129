<template>
	<div class="flex-col page">
		<div class="flex-col section_1">
			<span class="text_1">{{ title }}</span>
		</div>
		<div class="flex-col section_3">
			<div class="flex-col">
				<span class="list-item" :key="i" v-for="(title, i) in list">
					{{ i + 1 }}、{{ title.upkeepName }} <br />
					<br />
					<span style="color: #4e4e4e">{{ title.upkeepRequire }}</span>
					<van-radio-group
						v-model="radio[i].status"
						@change="onChange(title.id, radio[i].status, i)"
						direction="horizontal" :disabled="State == 3"
					>
						<van-radio name="0">检查正常</van-radio>
						<van-radio name="1">润滑</van-radio>
						<van-radio name="2">调整</van-radio>
						<van-radio name="3">更换</van-radio>
						<van-radio name="4">异常现象待修复</van-radio>
						<van-radio name="5">无此项</van-radio>
					</van-radio-group>
				</span>
			</div>
			<div v-if="State != 3" class="flex-col items-center button" @click="submit">
				<span>确认维保内容</span>
			</div>
		</div>
	</div>
</template>

<script>
import { itemList } from "@/api/Maintenance/Maintenance/all";
import { Toast } from "vant";
export default {
	data() {
		return {
			list: [],
			title: "维保任务",
			radio: [],
			Item: [],
			State:3,
		};
	},

	methods: {
		onChange(key, event, index) {
			this.radio[index].status = event;
		},
		submit() {
			if (this.radio !== "") {
				for (var i = 0; i < this.radio.length; i++) {
					if (this.radio[i].status == "") {
						Toast("必选项没有选择哦！！！");
						return false;
					}
				}
				for (var j = 0; j < this.radio.length; j++) {
					if (j === this.radio.length - 1) {
						this.Item +=
							"item_" + this.radio[j].id + "=" + this.radio[j].status;
					} else {
						this.Item +=
							"item_" + this.radio[j].id + "=" + this.radio[j].status + ",";
					}
					sessionStorage.setItem("Item" + this.$route.params.id, this.Item);
				}
				this.$router.push(
					"/maintenance/maintenance/arrive/" + this.$route.params.id
				);
			} else {
				this.$router.push(
					"/maintenance/maintenance/arrive/" + this.$route.params.id
				);
			}
		},
	},
	mounted() {
		// 初始渲染需要维保的选项列
		itemList({ id: this.$route.params.id }).then((res) => {
			if (res.data.Result === 200) {
				let resData = res.data.Data;

				this.State = resData.state;
				this.radio = [];
				for (var i = 0; i < resData.data.length; i++) {
					this.radio.push({ id: resData.data[i].id, status: resData.data[i].status.toString() });
				}
				this.list = resData.data;
			}
			let itemStr = sessionStorage.getItem("Item" + this.$route.params.id);
			// console.log(itemStr);
			// let itemList = itemStr.split(",");

			// if (itemList.length > 0) {
			// 	this.radio = [];
			// 	for (var i = 0; i < itemList.length; i++) {
			// 		let itemVal = itemList[i].split("=");
			// 		let itemId = itemVal[0].split("_");
			// 		this.radio.push({ id: itemId[1], status: itemVal[1] });
			// 	}
			// 	console.log(this.radio);
			// }
		});
	},
};
</script>

<style scoped>
@import "../../../assets/common.css";
.list-item {
	margin-left: 0.88rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1.06rem;
	white-space: nowrap;
}
.page {
	background-color: rgb(255, 255, 255);
	height: 100%;
	width: 100%;
	overflow-y: auto;
}
.section_1 {
	padding: 0 0 5.13rem;
	background: url("../../../assets/imgbg1_03.png") center no-repeat;
	background-size: cover;
}
.section_3 {
	margin: -2.56rem 0 -4.88rem;
	padding: 1.44rem 0.44rem 6.44rem;
	background: #fff;
	border-radius: 1rem 1rem 0 0;
	position: relative;
}
.section_2 {
	padding: 0.88rem 0.81rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	background-color: rgb(237, 237, 237);
}
.text_1 {
	margin-left: 1.5rem;
	margin-top: 1.88rem;
	color: rgb(255, 255, 255);
	font-size: 1.5rem;
	line-height: 1.44rem;
	white-space: nowrap;
}
.button {
	margin: 3.63rem 0.81rem 0;
	padding: 1.06rem 0;
	color: rgb(255, 255, 255);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	background-image: url("../../../assets/buttonbg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.image_1 {
	margin-right: 0.25rem;
	align-self: center;
	width: 1.13rem;
	height: 0.25rem;
}
.image {
	margin-bottom: 0.13rem;
	width: 0.81rem;
	height: 0.81rem;
}
.text {
	margin-left: 0.69rem;
}
.van-radio-group {
	padding: 1rem 0;
}
</style>
